import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">A lot is happening, <br /> Here are our upcoming events.</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article imgUrl={blog01} date="Sept. 16, 2022" text=" Join the STEM club as we begin our second year with CSUSB! Mingle with your peers, network..." />
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog02} date="Oct. 11, 2022" text="Join the STEM club as we learn about the planets, our Earth, and space all while doing some..." />
        <Article imgUrl={blog03} date="Oct. 21, 2022" text="Party off the mid-semester stress by celebrating Halloween with us! There will be festive ..." />
        <Article imgUrl={blog04} date="Dec. 02, 2022" text="Join the Science Success Center & your STEM club students as we study for finals week. Tut..." />
        <Article imgUrl={blog05} date="Jan. 30, 2023" text="Join us for The Louis Stokes Alliance for Minority Participation Program" />
      </div>
    </div>
  </div>
);

export default Blog;