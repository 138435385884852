import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <p>Events</p>
      <h3>Subscribe Today & Know When Our Next Special Events Will Take Place!</h3>
    </div>
    <div className="gpt3__cta-btn">
      <button type="button"><a href='https://accounts.google.com/v3/signin/identifier?dsh=S-268287743%3A1683573329114373&ifkv=Af_xneEIAjABTe6avrRb_DFYRxp-MRJUAJ6ct69GoTF5BJfwPfdWoIeIaQue85wuShiKSoc-_Qsq&flowName=GlifWebSignIn&flowEntry=ServiceLogin'>Sign in</a></button>
    </div>
  </div>
);

export default CTA;