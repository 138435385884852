import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Objectives" text="" />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">In support of the university’s goals and initiatives, The STEM Club works towards achieving the following objectives:</h1>
      <p>Explore the Library</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Assist" text="Assist with improving retention rates, student success, and learning through co-curricular experiences." />
      <Feature title="Enhance" text="Enhance academic success through intentional co-curricular experiences." />
      <Feature title="Develop" text="Develop university partnerships in order to create meaningful experiences." />
      <Feature title="Support" text="Support both current and future job functions through professional development opportunities." />
      <Feature title="Increase" text="Increase student participation in co-curricular experiences." />
      <Feature title="Utilize" text="Utilize assessment to create and modify programs and services to better meet the needs of our students" />
    </div>
  </div>
);

export default WhatGPT3;