import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Engaging',
    text: 'Engaging students in active co-curricular learning and developing soft skills in preparation for graduation and lifelong success. ',
  },
  {
    title: 'Developing & Forging',
    text: 'Developing students into leaders and forging educational partnerships that advance student learning.',
  },
  {
    title: 'Building',
    text: 'Building a supportive and inclusive community for students.',
  },
  {
    title: 'Retaining & Supporting',
    text: 'Retaining students and supporting their transition to and from college.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">The STEM Club is guided by the following key values and principles:</h1>
      <p>Our Values</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
