import React from 'react';
import possibilityImage from '../../assets/stem-education (1).png';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h4>Think about the future</h4>
      <h1 className="gradient__text">The possibilities are <br /> beyond your imagination</h1>
      <p>The CSUSB STEM club offers opportunities for students to explore their interests in science, technology, engineering, and mathematics, develop skills, and connect with professionals in their desired fields while promoting diversity and inclusion and fostering community outreach.</p>
      <h4>Visit TO-145 to Learn More</h4>
    </div>
  </div>
);

export default Possibility;