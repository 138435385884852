import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/stem-club-logo-white.png';
import './header.css';
import emailjs from '@emailjs/browser';

const sendEmail = (e) => {
  e.preventDefault();
  alert("Thank you for subscribing!");
  emailjs.sendForm('service_j1ih0s5', 'template_s8j5z8d', e.target, 'k0LLRr7Uos7BiWcR5');
}

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Interested in joining the CSUSB STEM Club?</h1>
      <p>The STEM Club will provide an environment that cultivates a sense of belonging, leadership experiences, and services to help promote student success.</p>

      <div className="gpt3__header-content__input">
        <form className='contact__form' onSubmit={sendEmail}>
        <input type="email" name="email_from" placeholder="Your Email Address" />
        <button type="submit">Subscribe</button>
        </form>
      </div>

      <div className="gpt3__header-content__people">
        <img src={people} />
        <p>70 people requested access a visit in last 24 hours</p>
      </div>
    </div>

    <div className="gpt3__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;